import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import Banner from '../Homepage/Components/Banner';
import Joyride, { STATUS } from "react-joyride";


export default function Reachus() {

    useEffect(()=>{
        setTimeout(function() {
           setState(prevState => ({
            ...prevState,
            run: true
          }))
        }, 5000); // 2000 milliseconds = 2 seconds

    },[])

    const [{ run, steps }, setState] = useState({
        run: false,
        steps: [
          {
            content: <h2>Lets Get Links Detail</h2>,
            locale: { skip: <strong>SKIP</strong> },
            placement: "center",
            target: "body"
          },
          {
            content: <h2> </h2>,
            placement: "top",
            target: "#step-1",
            title: "Facebook"
          },
          {
            content: <h2> </h2>,
            placement: "top",
            target: "#step-2",
            title: "Instagram"
          },
          {
            content: <h2></h2>,
            placement: "top",
            target: "#step-3",
            title: "Twitter"
          },
          {
            content: <h2></h2>,
            placement: "top",
            target: "#step-4",
            title: "Pintrest"
          },
          {
            content: <h2></h2>,
            placement: "top",
            target: "#step-5",
            title: "Linkedin"
          },
          {
            content: <h2></h2>,
            placement: "top",
            target: "#step-6",
            title: "Map"
          },
          
        ]
      });

    return (
        <>
        <div className='brand-container pt-10'>
        <Joyride
        continuous
        callback={() => {}}
        run={run}
        steps={steps}
        hideCloseButton
        scrollToFirstStep
        showSkipButton
        showProgress
      />
            <div className='flex justify-center flex-col items-center w-full'>
                {/* <img width={200} height={200} src="/assets/svg/logo.svg" alt="" /> */}
                <Banner />
                <h1 className='text-3xl md:text-3xl lg:text-3xl xl:text-5xl font-bold py-5'>Reach us at <br/> تواصل معنا على</h1>
            </div>
            <div id='social-links' className='flex justify-center py-10'>
                <div className='animate-pulse md:animate-none lg:animate-none xl:animate-none w-full md:w-2/5 lg:w-2/5 xl:w-1/5 grid grid-cols-3 place-items-center  gap-10 justify-center text-[#FF6455]'>
                    <Link id='step-1' to='https://facebook.com/5servonline' target='_blank'>
                    <img className=' hover:rotate-6 transition-all duration-150' width={500} height={500} src={'/assets/svg/social-icons/facebook.svg'} />
                    </Link>
                    <Link id='step-2' to='https://www.instagram.com/5servonline/' target='_blank'>
                        <img className=' hover:rotate-6 transition-all duration-150' width={500} height={500} src={'/assets/svg/social-icons/instagram.svg'} />
                    </Link>
                    <Link id='step-3' to='https://twitter.com/5servonline' target='_blank'>
                        <img className=' hover:rotate-6 transition-all duration-150' width={500} height={500} src={'/assets/svg/social-icons/twitter.svg'} />
                    </Link>
                    <Link id='step-4' to='https://pinterest.com/5serv' target='_blank'>
                        <img className=' hover:rotate-6 transition-all duration-150' width={500} height={500} src={'/assets/svg/social-icons/pinterest.svg'} />
                    </Link>
                    <Link id='step-5' to='https://www.linkedin.com/company/5serv/' target='_blank'>
                    <img className=' hover:rotate-6 transition-all duration-150' width={500} height={500} src={'/assets/svg/social-icons/linkedin.svg'} />

                    </Link>
                    <Link id='step-6' to='https://www.google.com/maps' target='_blank'>
                    <img className=' hover:rotate-6 transition-all duration-150' width={500} height={500} src={'/assets/svg/social-icons/map.svg'} />

                    </Link>

                </div>
            </div>

            <div class="flex gap-5 py-10  justify-center">
                <button type="button" onClick={() => { alert('comming soon...') }} class="flex items-center justify-center w-48 mt-3 text-white bg-black h-14 rounded-lg">
                    <div class="mr-3">
                        <svg viewBox="0 0 384 512" width="30">
                            <path fill="currentColor" d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z">
                            </path>
                        </svg>
                    </div>
                    <div>
                        <div class="text-xs">
                            Download on the
                        </div>
                        <div class="-mt-1 font-sans text-xl font-semibold">
                            App Store
                        </div>
                    </div>
                </button>

                <a target='_blank' href='https://play.google.com/store/apps/details?id=com.shrewd.fiveserv' class="flex items-center justify-center w-48 mt-3 text-white bg-black rounded-lg h-14">
                    <div class="mr-3">
                        <svg viewBox="30 336.7 120.9 129.2" width="30">
                            <path fill="#FFD400" d="M119.2,421.2c15.3-8.4,27-14.8,28-15.3c3.2-1.7,6.5-6.2,0-9.7  c-2.1-1.1-13.4-7.3-28-15.3l-20.1,20.2L119.2,421.2z">
                            </path>
                            <path fill="#FF3333" d="M99.1,401.1l-64.2,64.7c1.5,0.2,3.2-0.2,5.2-1.3  c4.2-2.3,48.8-26.7,79.1-43.3L99.1,401.1L99.1,401.1z">
                            </path>
                            <path fill="#48FF48" d="M99.1,401.1l20.1-20.2c0,0-74.6-40.7-79.1-43.1  c-1.7-1-3.6-1.3-5.3-1L99.1,401.1z">
                            </path>
                            <path fill="#3BCCFF" d="M99.1,401.1l-64.3-64.3c-2.6,0.6-4.8,2.9-4.8,7.6  c0,7.5,0,107.5,0,113.8c0,4.3,1.7,7.4,4.9,7.7L99.1,401.1z">
                            </path>
                        </svg>
                    </div>
                    <div>
                        <div class="text-xs">
                            GET IT ON
                        </div>
                        <div class="-mt-1 font-sans text-xl font-semibold">
                            Google Play
                        </div>
                    </div>
                </a>
            </div>

        </div>
        </>

    )
}



function FaFacebook(props) {
    return <svg stroke="currentColor" fill="currentColor" strokeWidth={0} viewBox="0 0 512 512" height="4em" width="4em" {...props}><path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z" /></svg>;
}


function AiFillInstagram(props) {
    return <svg stroke="currentColor" fill="currentColor" strokeWidth={0} viewBox="0 0 1024 1024" height="4em" width="4em" {...props}><path d="M512 378.7c-73.4 0-133.3 59.9-133.3 133.3S438.6 645.3 512 645.3 645.3 585.4 645.3 512 585.4 378.7 512 378.7zM911.8 512c0-55.2.5-109.9-2.6-165-3.1-64-17.7-120.8-64.5-167.6-46.9-46.9-103.6-61.4-167.6-64.5-55.2-3.1-109.9-2.6-165-2.6-55.2 0-109.9-.5-165 2.6-64 3.1-120.8 17.7-167.6 64.5C132.6 226.3 118.1 283 115 347c-3.1 55.2-2.6 109.9-2.6 165s-.5 109.9 2.6 165c3.1 64 17.7 120.8 64.5 167.6 46.9 46.9 103.6 61.4 167.6 64.5 55.2 3.1 109.9 2.6 165 2.6 55.2 0 109.9.5 165-2.6 64-3.1 120.8-17.7 167.6-64.5 46.9-46.9 61.4-103.6 64.5-167.6 3.2-55.1 2.6-109.8 2.6-165zM512 717.1c-113.5 0-205.1-91.6-205.1-205.1S398.5 306.9 512 306.9 717.1 398.5 717.1 512 625.5 717.1 512 717.1zm213.5-370.7c-26.5 0-47.9-21.4-47.9-47.9s21.4-47.9 47.9-47.9 47.9 21.4 47.9 47.9a47.84 47.84 0 0 1-47.9 47.9z" /></svg>;
}


function AiFillTwitterCircle(props) {
    return <svg stroke="currentColor" fill="currentColor" strokeWidth={0} viewBox="0 0 1024 1024" height="4em" width="4em" {...props}><path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm215.3 337.7c.3 4.7.3 9.6.3 14.4 0 146.8-111.8 315.9-316.1 315.9-63 0-121.4-18.3-170.6-49.8 9 1 17.6 1.4 26.8 1.4 52 0 99.8-17.6 137.9-47.4-48.8-1-89.8-33-103.8-77 17.1 2.5 32.5 2.5 50.1-2a111 111 0 0 1-88.9-109v-1.4c14.7 8.3 32 13.4 50.1 14.1a111.13 111.13 0 0 1-49.5-92.4c0-20.7 5.4-39.6 15.1-56a315.28 315.28 0 0 0 229 116.1C492 353.1 548.4 292 616.2 292c32 0 60.8 13.4 81.1 35 25.1-4.7 49.1-14.1 70.5-26.7-8.3 25.7-25.7 47.4-48.8 61.1 22.4-2.4 44-8.6 64-17.3-15.1 22.2-34 41.9-55.7 57.6z" /></svg>;
}


function GrPinterest(props) {
    return <svg stroke="currentColor" fill="currentColor" strokeWidth={0} viewBox="0 0 24 24" height="4em" width="4em" {...props}><path fillRule="evenodd" d="M12,0 C5.37225,0 0,5.37225 0,12 C0,17.0835 3.16275,21.426 7.62675,23.17425 C7.52175,22.22475 7.42725,20.76825 7.66875,19.73175 C7.88625,18.79575 9.07575,13.767 9.07575,13.767 C9.07575,13.767 8.7165,13.0485 8.7165,11.98575 C8.7165,10.317 9.684,9.07125 10.88775,9.07125 C11.9115,9.07125 12.4065,9.84 12.4065,10.76175 C12.4065,11.7915 11.751,13.3305 11.41275,14.757 C11.13,15.95175 12.01125,16.926 13.1895,16.926 C15.3225,16.926 16.962,14.67675 16.962,11.43075 C16.962,8.5575 14.89725,6.54825 11.949,6.54825 C8.535,6.54825 6.531,9.1095 6.531,11.75625 C6.531,12.7875 6.92775,13.89375 7.4235,14.4945 C7.52175,14.61375 7.536,14.71725 7.50675,14.83875 C7.416,15.21825 7.2135,16.03275 7.17375,16.2 C7.12125,16.419 6.99975,16.46625 6.7725,16.3605 C5.27325,15.66225 4.3365,13.4715 4.3365,11.71125 C4.3365,7.926 7.08675,4.44975 12.2655,4.44975 C16.428,4.44975 19.6635,7.416 19.6635,11.3805 C19.6635,15.516 17.05575,18.8445 13.43625,18.8445 C12.2205,18.8445 11.0775,18.21225 10.686,17.466 C10.686,17.466 10.0845,19.75725 9.93825,20.319 C9.6675,21.36075 8.93625,22.66725 8.4465,23.4645 C9.57,23.8125 10.76325,24 12,24 C18.62775,24 24,18.627 24,12 C24,5.37225 18.62775,0 12,0" /></svg>;
}


function AiFillLinkedin(props) {
    return <svg stroke="currentColor" fill="currentColor" strokeWidth={0} viewBox="0 0 1024 1024" height="4em" width="4em" {...props}><path d="M880 112H144c-17.7 0-32 14.3-32 32v736c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V144c0-17.7-14.3-32-32-32zM349.3 793.7H230.6V411.9h118.7v381.8zm-59.3-434a68.8 68.8 0 1 1 68.8-68.8c-.1 38-30.9 68.8-68.8 68.8zm503.7 434H675.1V608c0-44.3-.8-101.2-61.7-101.2-61.7 0-71.2 48.2-71.2 98v188.9H423.7V411.9h113.8v52.2h1.6c15.8-30 54.5-61.7 112.3-61.7 120.2 0 142.3 79.1 142.3 181.9v209.4z" /></svg>;
}



function FaMapMarkerAlt(props) {
    return <svg stroke="currentColor" fill="currentColor" strokeWidth={0} viewBox="0 0 384 512" height="4em" width="4em" {...props}><path d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z" /></svg>;
}

