const ServiceData = [
    {
        id: 1,
        name: 'laundry',
        image: '/assets/images/services/ourSer1.png'
    },
    {
        id: 2,
        name: 'ac repair',
        image: '/assets/images/services/ourSer2.png'
    },
    {
        id: 3,
        name: 'pest control',
        image: '/assets/images/services/ourSer3.png'
    },
    {
        id: 4,
        name: 'baby sitting',
        image: '/assets/images/services/ourSer4.png'
    },
    {
        id: 5,
        name: 'home maids',
        image: '/assets/images/services/ourSer5.png'
    },
    {
        id: 6,
        name: 'electrician',
        image: '/assets/images/services/ourSer6.png'
    },
    {
        id: 7,
        name: 'plumbing',
        image: '/assets/images/services/ourSer7.png'
    },
    {
        id: 8,
        name: 'iorning',
        image: '/assets/images/services/ourSer8.png'
    },
    {
        id: 9,
        name: 'car wash',
        image: '/assets/images/services/ourSer9.png'
    },
    {
        id: 10,
        name: 'sofa cleaning',
        image: '/assets/images/services/ourSer10.png'
    },
    {
        id: 11,
        name: 'kitchen cleaning',
        image: '/assets/images/services/ourSer11.png'
    },
    {
        id: 12,
        name: 'bathroom cleaning',
        image: '/assets/images/services/ourSer12.png'
    },
    {
        id: 13,
        name: 'home cleaning',
        image: '/assets/images/services/ourSer13.png'
    },
    {
        id: 14,
        name: 'bed bug',
        image: '/assets/images/services/ourSer14.png'
    },
    {
        id: 15,
        name: 'carpet cleaning',
        image: '/assets/images/services/ourSer15.png'
    },
]

module.exports = ServiceData