import React, { useEffect, useState } from 'react'
import Button from '../../../components/Button'
import ServiceData from './Data';
import { NotificationService } from '../../../services/notificationservice';
import useFetchData from '../../../customhooks/useFetchData';

function BookingModal({ state, Handler }) {
    const [changeState, setChangestate] = useState(false);
    const [serviceState, setServiceState] = useState(true);
    const [responseData, setresponseData] = useState({service_name:'', user_name:'', user_mobile:''});

    const {makeRequest, loading} = useFetchData()

    useEffect(() => {
        setServiceState(true);
        setChangestate(false)
    }, [state])

    const ChooseService = (data) => {
        setresponseData(resData => ({
            ...resData,
            service_name: data
        }))
        setServiceState(false);
    }

    const FormData = (event)=>{
        const name = event.target.name;
        const value = event.target.value;
        setresponseData(resData => ({
            ...resData,
            [name]: value
        }))
    }

    const formSubmit = async () =>{

        //TODO VALIDATION

        if(responseData.service_name ===''){
            setServiceState(true);
            setChangestate(false)
        }else if(responseData.user_mobile ==='' || responseData.user_name === ''){
            NotificationService('please Enter All Fields','error','top-right')
        }else {
            //TODO MAKE API CALL

            const data_construct = {
                service_type: responseData.service_name,
                name:responseData.user_name,
                mobile_number:responseData.user_mobile
            }


            const data = await makeRequest('/service-bookings/instant-booking','post', data_construct)

            if(data){
                setChangestate(true)
                NotificationService(data.message, 'success', 'top-right')

            }

        }


    }


    const CloseHandling = (e) => {
        if (e.target.id === 'modal') Handler(false)
    }

    return (
        <div id='modal' onClick={CloseHandling} className={`${state ? '' : 'hidden'} fixed inset-0 bg-black/20 z-50 flex justify-center items-center px-3`}>
            {serviceState ? 
            <div className='bg-white px-5 py-5 w-fit my-10 rounded-lg'>
                <p className='font-bold text-left text-xl pb-3'>Choose Service</p>
                <div className=' h-[500px] overflow-hidden overflow-y-scroll py-10 px-10'>
                <div className='bookOurServ grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-5 x:grid-cols-5 gap-2 '>
                    {ServiceData.map((list, index) =>
                        <ServiceCard key={index} image={list.image} name={list.name} Handler={ChooseService} />
                    )}
                </div>
                </div>
            </div>
            :

            <div className='bg-white h-[450px] w-[400px] p-5 '>
                {changeState ? <SuccessRequest Handler={Handler} /> : <MakeRequest Handler={formSubmit} formHandling={FormData} />}
            </div>
              }

        </div>

    )
}

export default BookingModal

const MakeRequest = ({ Handler, formHandling }) => {
    return (
        <>
            <div className='flex flex-col justify-between h-full'>

                <p className='text-3xl font-bold'>Service Request</p>
                <div className='flex flex-col gap-3'>
                    <div className='flex flex-col gap-3'>
                        <label for="user_name">Name</label>
                        <input name='user_name' onChange={formHandling}  id='user_name' type="text" className='w-full h-16 bg-[#D9D9D9]/20 px-3 outline-none placeholder:text-black/40' placeholder='Enter your name' />
                    </div>
                    <div className='flex flex-col gap-3'>
                        <label for="mobile_number" className='capitalize'>mobile number</label>
                        <input name='user_mobile' onChange={formHandling} id='mobile_number' type="text" className='w-full h-16 bg-[#D9D9D9]/20 px-3 outline-none placeholder:text-black/40' placeholder='+974' />
                    </div>
                </div>
                <div className='px-10 flex flex-col items-center gap-5'>
                    <hr className='border border-black mx-10 w-full' />
                    <Button onClick={() => Handler(true)} variant={'primary'} rounded={false} className={'rounded-md px-16 '}>submit</Button>
                </div>
            </div>

        </>
    )
}

const SuccessRequest = ({ Handler }) => {
    return (
        <>
            <div className='flex flex-col justify-between h-full'>

                <p className='text-3xl font-bold text-center'>Thanks you !</p>
                <div className='grid place-content-center'>
                    <img src="/assets/images/tq.png" alt="" />
                </div>
                <div className='px-10 flex flex-col items-center gap-5'>
                    <p className='text-center text-xs'>We are processing your request ! Our customer care will get in touch with you!</p>
                    <hr className='border border-black mx-10 w-full' />
                    <Button onClick={() => Handler(false)} variant={'primary'} rounded={false} className={'rounded-md px-16 '}>Back to home</Button>
                </div>
            </div>

        </>
    )
}


const ServiceCard = (props) => {
    return (
        <div onClick={()=>{props.Handler(props.name)}} className='flex flex-col items-center gap-5 cursor-pointer active:opacity-50'>
            <img draggable={false} className='h-20 aspect-square object-contain ' src={props.image} alt="" />
            <p className='text-sm whitespace-nowrap capitalize'>{props.name}</p>
        </div>
    )
}