import React from 'react'
import PropTypes from "prop-types";
import { classNames } from '../../utility/Classnames';



function Button({ className, children, rounded, variant, ...props }) {
    return (
        <button
            className={classNames(
                "px-[20px] py-[13px] cursor-pointer select-none capitalize [outline:none] active:opacity-50 transition-all duration-500  ",
                rounded ? "rounded-[10px]" : "",
                variant === "primary"
                    ? "bg-[#FF6455] text-white hover:bg-[#F5DD00] drop-shadow-[3px_11px_12px_#f5dd0052]"
                    : "",
                variant === 'secondary' ? 'bg-white hover:bg-[#F5DD00] text-[#FF6455]' : '',
                className
            )}
            {...props}>
            {children}
        </button>
    )
}

export default Button

Button.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
    rounded: PropTypes.bool.isRequired,
    variant: PropTypes.oneOf([
        "primary",

    ]),
};