import React, { useContext, useEffect, useState } from 'react'
import { ScrolltoTop } from '../../utility';
import Whychooseus from './Components/Whychooseus';
import Ourservices from './Components/Ourservices';
import Howitworks from './Components/Howitworks';
import Banner from './Components/Banner';
import Appdownload from './Components/Appdownload';
import { Whatpeoplesays } from './Components/Whatpeoplesays';
import BookingModal from './Components/BookingModal';
import Servicejourney from './Components/Servicejourney';
import { AuthContext } from '../../ContextApi/AppContext';

function Homepage() {
    document.title = '5 serv';
    useEffect(() => {
        ScrolltoTop();
    }, [])

    const [modalState, setmodalState] = useState(false)
    const { setPopupState } = useContext(AuthContext)


    return (
        <>
            <Banner />
            <Whychooseus />
            <Ourservices />
            <Appdownload />
            
            {/* <Servicejourney /> */}
            <Whatpeoplesays modalHandler={setPopupState} />
        </>
    )
}

export default Homepage