import React, { useEffect, useState } from 'react'

function Servicejourney() {

    const [selectedData, setselectedData] = useState(0)
    const DataAr = [
        "Nothing inspires cleanliness more than an unexpected guest",
        "content - 2",
        "content - 3",
        "content - 4",
    ]

    const Datachange = () => {
        const length = DataAr.length - 1
        if (selectedData < length) {
            setselectedData(selectedData + 1)
        } else {
            setselectedData(0)
        }

    }

    useEffect(() => {
        setTimeout(() => {
            Datachange()
        }, 3000);
    }, [selectedData])

    return (
        <>
            <div className='brand-container'>
                <div className='flex flex-col md:flex-row lg:flex-row xl:flex-row justify-between h-auto md:h-[450px] lg:h-[450px] xl:h-[450px] items-center'>
                    <div className='flex items-end h-fit md:h-full lg:h-full xl:h-full'>
                        <div className='relative w-fit flex items-center flex-col gap-5 py-10 mt-5 after:content-[""] after:w-5 after:h-5 after:bg-black after:rounded-full after:absolute after:top-[55%] after:md:top-0 after:lg:top-0 after:xl:top-0 after:-right-10 after:md:right-[45%] after:lg:right-[45%] after:xl:right-[45%]'>
                            <p className='text-center capitalize'>search service</p>
                            <div className='w-[100px] h-[100px] bg-black rounded-lg'></div>
                        </div>
                    </div>
                    <div className='flex items-start h-fit md:h-full lg:h-full xl:h-full'>
                        <div className='relative w-fit flex items-center flex-col-reverse gap-5 py-10 mt-5  after:content-[""] after:w-5 after:h-5 after:bg-black after:rounded-full after:absolute after:bottom-0 after:right-[45%]'>
                            <p className='text-center capitalize'>book service</p>
                            <div className='w-[100px] h-[100px] bg-black rounded-lg'></div>
                        </div>
                    </div>

                    <div className='flex items-end h-fit md:h-full lg:h-full xl:h-full'>
                        <div className='relative w-fit flex items-center flex-col gap-5 py-10 mt-5 after:content-[""] after:w-5 after:h-5 after:bg-black after:rounded-full after:absolute after:top-[55%] after:md:top-0 after:lg:top-0 after:xl:top-0 after:-right-10 after:md:right-[45%] after:lg:right-[45%] after:xl:right-[45%]'>
                            <p className='text-center capitalize'>Customer care will confirm your booking</p>
                            <div className='w-[100px] h-[100px] bg-black rounded-lg'></div>
                        </div>
                    </div>
                    <div className='flex items-start h-fit md:h-full lg:h-full xl:h-full'>
                        <div className='relative w-fit flex items-center flex-col-reverse gap-5 py-10 mt-5  after:content-[""] after:w-5 after:h-5 after:bg-black after:rounded-full after:absolute after:bottom-0 after:right-[45%]'>
                            <p className='text-center capitalize'>Our service guy will visit</p>
                            <div className='w-[100px] h-[100px] bg-black rounded-lg'></div>
                        </div>
                    </div>



                </div>


            </div>
            <div className='w-full bg-[#FFEDD9] py-10 my-10'>
                <p className='text-center text-lg'>"{DataAr[selectedData]}"</p>
            </div>

        </>
    )
}

export default Servicejourney