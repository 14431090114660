import React from 'react'

function Whychooseus() {
    return (
        <>
            <div className='brand-container pt-15 pb-15 whyChooseMain'>
                <p className='text-center capitalize text-3xl pb-10 font-semibold'>why choose us?</p>
                <div className='whyChoose flex flex-col md:flex-row lg:flex-row xl:flex-row md:gap-10 justify-center w-full pt-5 pb-3'>
                    <Card image={'/assets/images/whyChoose/whyChoose1.png'} title={'Expert workers'} content={'Iriure reprimique pro ea, errem luptatum quo an, utinam nullam alienum te est.'} />
                    <Card image={'/assets/images/whyChoose/whyChoose2.png'} title={'Service on Demand'} content={'Iriure reprimique pro ea, errem luptatum quo an, utinam nullam alienum te est.'} />
                    <Card image={'/assets/images/whyChoose/whyChoose3.png'} title={'Genuine Pricing'} content={'Iriure reprimique pro ea, errem luptatum quo an, utinam nullam alienum te est.'} />

                    {/* <Card image={'/assets/images/whyChoose/whyChoose1.png'} title={'Expert Workers'} content={'Background Verified Experts'} textcolor={'text-[#32739B]'} bgcolor={'bg-[#E7F6FF]'} /> */}

                </div>

            </div>
        </>

    )
}

export default Whychooseus

const Card = (props) => {
    return (
        <>
            
                <div className=' bg-white px-10 py-10 rounded-lg text-center '>
                    <img draggable={false} className='animate-ping aspect-square object-contain mx-auto w-12 pb-2' style={{margin:"auto",}} src={props.image} alt="" />
                    <p className={`select-none pt-2 px-10 py-3 ${props.bgcolor} ${props.textcolor} capitalize text-xl whitespace-nowrap font-medium `}>{props.title}</p>
                    <p className='select-none capitalize text-sm'>{props.content}</p>
                </div>
            


        </>
    )
}