import React from 'react'

const Appdownload = () => {
  return (
   <>
   <div className='brand-container mt-20'>
    <div className='bg-orange w-full h-full md:h-[400px] lg:h-[400px] xl:h-[400px] rounded-xl overflow-hidden
    relative flex flex-col md:flex-row lg:flex-row xl:flex-row justify-between items-center px-20 py-10'
    >
        
        {/* <img draggable={false} className='-mb-[200px] -ml-32 hidden md:block lg:block xl:block' src="/assets/svg/app-moc.svg" alt="" />
        <img draggable={false} className=' block md:hidden lg:hidden xl:hidden z-10' src="/assets/svg/app-screen-moc.svg" alt="" /> */}
          <div className=''>
            <img draggable={false} className='md:block lg:block xl:block mobile' src="/assets/images/appDownload/mobile.png" alt="" />
          </div>
         <div className=''>
          <h2 className='text-white font-medium fs-36 pb-3'>Download Our App</h2>
          <p className='text-white'>Download Now and Elevate <br/>Your Experience with Us!"</p>
            <div className='flex flex-col xl:flex-row gap-3 z-10 pt-10'>
                <img className='active:opacity-50 cursor-pointer' src="/assets/images/icons/appstore.png" alt="" />
                <img className='active:opacity-50 cursor-pointer' src="/assets/images/icons/playstore-dw.png" alt="" />
            </div>
         </div>
    </div>

   </div>

   </>
  )
}

export default Appdownload

