import React from 'react'

function NotFoundPage() {
    document.title = '5 serv';
  return (
    <div className='h-screen flex justify-center items-center'>
        <p className='text-3xl font-bold'> 404 / NOT FOUND</p>
    </div>
  )
}

export default NotFoundPage