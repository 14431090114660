import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
    const SocialLinks = [
        {
            id: 1,
            name: 'youtube',
            icon: '/assets/images/icons/youtube.png',
            link: '',
        },
        {
            id: 1,
            name: 'twitter',
            icon: '/assets/images/icons/twitter.png',
            link: '',
        },
        {
            id: 1,
            name: 'instagram',
            icon: '/assets/images/icons/instagram.png',
            link: '',
        },
        {
            id: 1,
            name: 'facebook',
            icon: '/assets/images/icons/facebook.png',
            link: '',
        },
    ]

    const FooterContent = [
        {
            id: 1,
            title: 'Services',
            list: [
                {
                    id: 1,
                    name: 'Home Services',
                    path: '/',
                },
            ]
        },
        {
            id: 2,
            title: 'Company',
            list: [
                {
                    id: 1,
                    name: 'About',
                    path: '/',
                },
                {
                    id: 2,
                    name: 'Contact',
                    path: '/',
                },
                {
                    id: 3,
                    name: 'Careers',
                    path: '/',
                },
            ]
        },
        {
            id: 2,
            title: 'Policies',
            list: [
                {
                    id: 1,
                    name: 'Terms and Conditions',
                    path: '/terms-and-condition',
                },
                {
                    id: 2,
                    name: 'Privacy',
                    path: '/privacy-policy',
                },
                {
                    id: 3,
                    name: 'Refunds and Cancellations',
                    path: '/',
                },
            ]
        },
    ]

    return (
        <>
            <div className='bg-[#1A2E35] text-white py-2'>
                <div className='brand-container'>

                    <div className='w-full flex flex-col gap-5 md:flex-row lg:flex-row xl:flex-row justify-between items-start md:items-center lg:items-center xl:items-center py-3 border-b border-white/30'>
                        <img className='h-20 object-contain aspect-video' src="/assets/svg/logo.svg" alt="" />
                        <div className='flex gap-2'>
                            <p className='capitalize'>follow us on</p>
                            <ul className='flex items-center gap-2'>
                                {SocialLinks.map((item, index) =>
                                    <li key={index}>
                                        <img className='w-5 aspect-square object-contain cursor-pointer' src={item.icon} alt="" />
                                    </li>
                                )}
                            </ul>
                        </div>
                        <div className='flex gap-2 items-center'>
                            <p className='capitalize'>download app</p>
                            <img className='w-5 aspect-square object-contain cursor-pointer' src="/assets/images/icons/playstore.png" alt="" />
                        </div>

                    </div>

                    <div className='flex justify-between flex-col gap-5 md:flex-row lg:flex-row xl:flex-row md:px-10 lg:px-10 xl:px-10 py-10 border-b border-white/30'>

                        {FooterContent.map((item, index) =>
                            <>
                            <div>
                                <p className='pb-3' key={index}>{item.title}</p>
                                <ul className='flex flex-col gap-2'>
                                    {item.list.map((list, index)=> 
                                    <li key={index}>
                                       <Link to={list.path}>
                                       <p className='text-[#AAAAAA] capitalize text-sm cursor-pointer active:opacity-50 select-none'>{list.name}</p>
                                       </Link> 
                                    </li>
                                    )}
                                </ul>
                            </div>
                            </>

                        )}

                    </div>

                    <p className='text-center py-5 text-[#AAAAAA]'>Copyright 2023 All Rights Reserved</p>

                </div>
            </div>
        </>
    )
}

export default Footer