import React from 'react'
import ServiceData from './Data'

function Ourservices() {

    return (
        <>
            <div className='brand-container py-20 bg-orangrlight overflow-hidden relative'>
                <img src='assets/images/services/ourSerLeft.png' className='ourSerLeft' />
                <img src='assets/images/services/ourSerright.png' className='ourSerRight' />
                <p className='text-center capitalize text-3xl pb-10 font-semibold'>Our Services</p>
                <div className='ourService grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 x:grid-cols-5 gap-5 px-2 md:px-20 lg:px-48 xl:px-48 '>
                    {ServiceData.map((list, index) =>
                        <ServiceCard key={index} image={list.image} name={list.name} />
                    )}
                </div>

            </div>
        </>
    )
}

export default Ourservices

const ServiceCard = (props) => {
    return (
        <div className='ourSerCon flex flex-col items-center gap-5'>
            <div className='relative serImg-wrapper'>
                <img draggable={false} src={props.image} alt="" />
            </div>
            <p className='text-sm whitespace-nowrap pt-1'>{props.name}</p>
        </div>
    )
}

