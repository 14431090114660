import React from 'react'
import Button from '../Button'
import { useNavigate } from 'react-router'

function Navbar({modalHandler}) {
    const navigate = useNavigate();
    return (
        <>
        <div className='sticky top-0 w-full bg-white z-20'>
            <header className='brand-container bg-white drop-shadow-sm'>
                <div className='py-3 flex justify-between items-center '>
                    <div className='flex gap-5 items-center'>
                        <img onClick={()=> navigate('/')} className='h-20 cursor-pointer object-contain aspect-video' src="/assets/svg/logo.svg" alt="" />
                        <div className='hidden md:flex lg:flex xl:flex items-center  bg-white rounded-lg px-3 py-3 shadow-md w-fit'>
                            <input placeholder='Search service ' id='sd' className='lg:w-[350px] xl:w-[350px] ml-3 outline-none' type="text" />
                            <label for='sd' className='cursor-pointer hover:rotate-90 transition-all duration-300'>
                                <img src="/assets/svg/search-icon.svg" alt="" />
                            </label>
                        </div>
                    </div>
                    <Button onClick={()=> modalHandler(true)} variant={'primary'} rounded={false}>
                        Easy Booking الحجز سهل
                    </Button>
                </div>

            </header>
        </div>
        </>
    )
}

export default Navbar