import axios from 'axios';
import jwt_decode from "jwt-decode";
import dayjs from 'dayjs';
import { LocalStorageService } from './localstorageservice';

class ApiServiceNew {
    static axiosInstance;
    /**
     * Initialize for Axios Instance for interceptor
     */
    static initialize() {
        // Create a new instance of Axios with default configurations
        this.axiosInstance = axios.create({
            baseURL: 'https://customer-api.5serv.com/api/v1', // Set your API base URL here
            // baseURL: 'https://dev-customer-api.5serv.com/api/v1',
            timeout: 5000, // Set request timeout in milliseconds
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                // Add any common headers you need for your requests
            },
        });

        // Request interceptor: Runs before each request is sent
        this.axiosInstance.interceptors.request.use(
            async (config) => {
                const accessToken = LocalStorageService.getItem('accessToken_serv');
                if (accessToken) {
                    const refreshToken = LocalStorageService.getItem('refreshToken_serv');
                    const decoded = jwt_decode(accessToken);
                    const currentTime = dayjs().unix();
                    if (decoded.exp > currentTime) {
                        config.headers['Authorization'] = `Bearer ${accessToken}`;
                        return config;

                    } else {
                        //TODO MAKE JWT REFRESH TOKEN API HIT
                        LocalStorageService.removeItem('accessToken');
                        const token = await this.refreshToken();
                        config.headers['Authorization'] = `Bearer ${token}`;
                        return config;

                    }
                } else {
                    return config
                }
            },
            (error) => {
                // Handle request error here
                return Promise.reject(error);
            }
        );

        // Response interceptor: Runs after each response is received
        this.axiosInstance.interceptors.response.use(
            (response) => {
                // You can modify the response data here before it's returned to the caller
                return response.data;
            },
            (error) => {
                // return Promise.reject(error);
                return error
            }
        );
    }
    /**
     * AXIOS GET METHOD
     * @param {*} endpoint 
     * @param {*} params 
     * @returns 
     */
    static async get(endpoint, params = {}) {
        try {
            const response = await this.axiosInstance.get(endpoint, { params });
            return response;
        } catch (error) {
            throw error;
        }
    }

    /**
     * AXIOS POST METHOD
     * @param {*} endpoint 
     * @param {*} data 
     * @returns 
     */
    static async post(endpoint, data = {}) {
        try {
            const response = await this.axiosInstance.post(endpoint, data);
            return response;
        } catch (error) {
            throw error;
        }
    }

    // Add other HTTP methods (PUT, DELETE, etc.) as needed...

    static refreshToken = async () => {
        try {
            // TODO GET REFRESH TOKEN
            const refreshtoke = await LocalStorageService.getItem('refreshToken_serv');
            const payload = {
                refresh_token: refreshtoke
            }

            const Response = await this.post('/auth/refresh-token', payload)

            // TODO ONCE ALL DONE
            if (Response.status) {
                LocalStorageService.setItem('accessToken_serv', Response.data.accessToken)
                LocalStorageService.setItem('userType', 'guest')
                LocalStorageService.setItem('refreshToken_serv', Response.data.refreshToken)
                // window.location.reload();
            }

            return Response.data.accessToken
        }
        catch (error) {
            console.error(error)
        }
    }


}

// Initialize the Axios instance when the application starts
ApiServiceNew.initialize();

export default ApiServiceNew;
