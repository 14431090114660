import React from 'react'

function Howitworks() {
    return (
        <>
            <div className='w-full bg-[#FFEDD9]'>
                <div className='brand-container py-10'>
                    <p className='text-center py-5 capitalize text-3xl pb-10 relative z-10'>How it works</p>
                    <div className='flex flex-col lg:flex-row xl:flex-row items-center lg:items-stretch xl:items-stretch  justify-center gap-20 '>
                        <img className=' md:scale-100 lg:scale-150 xl:scale-150 aspect-video object-contain' src="/assets/gif/howitworks.gif" alt="" />
                        <div className='flex flex-col gap-5  justify-between '>
                          <ContentCard title={'Explore Service'} content={'Explore our services by inquiring about us.'} img={'assets/images/icons/search.png'} />
                          <ContentCard title={'Book Appointment'} content={'Book your appointment , get instant service.'} img={'assets/images/icons/booking.png'} />
                          <ContentCard title={'Get Service'} content={'You chill while we process it.'} img={'assets/images/icons/service.png'} />

                        </div>
                    </div>

                </div>


            </div>
        </>
    )
}

export default Howitworks

const ContentCard = ({title, content, img}) => {
    return (
        <>
            <div className='flex items-center gap-5'>
                <img className='w-14 aspect-square object-contain' src={img} alt="" />
                <div className='flex flex-col justify-between gap-2'>
                    <p className='font-bold text-xl'>{title}</p>
                    <p className='text-sm'>{content}</p>
                </div>
            </div>
        </>
    )
}