import React, { useRef } from 'react'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper/modules';


// Import Swiper styles
import 'swiper/css';

function Banner() {

    const BannerData = [
        {
            id: 1,
            title: '',
            content: '',
            img: '/assets/images/banner/bnr1.png'
        },
        {
            id: 2,
            title: '',
            content: '',
            img: '/assets/images/banner/bnr2.png'
        },
        {
            id: 3,
            title: '',
            content: '',
            img: '/assets/images/banner/bnr3.png'
        },
        {
            id: 4,
            title: '',
            content: '',
            img: '/assets/images/banner/bnr4.png'
        },

    ]

    const navigationPrevRef = useRef(null)
    const navigationNextRef = useRef(null)
    

    return (
        <>
            <div className='brand-container bg-bottom py-10' 
            style={{ backgroundImage: "url('/assets/images/banner/banner-bg.png')", backgroundSize:'100% 100%',}} >

                <div className='relative'>

                    <div className='absolute inset-0 hidden justify-between items-center z-0  md:flex lg:flex xl:flex'>
                        
                        <div ref={navigationPrevRef} className='w-8 h-8 -ml-10 select-none bg-white shadow-lg rounded-full flex justify-center items-center cursor-pointer active:opacity-50 border border-black/10'>
                            <img className='rotate-180' src="/assets/svg/arrow.svg" alt="" />
                        </div>
                        <div ref={navigationNextRef} className='w-8 h-8 -mr-10 select-none bg-white shadow-lg rounded-full flex justify-center items-center cursor-pointer active:opacity-50 border border-black/10'>
                            <img className='' src="/assets/svg/arrow.svg" alt="" />
                        </div>
                    </div>

                    <Swiper
                        navigation={{
                            prevEl: navigationPrevRef.current,
                            nextEl: navigationNextRef.current,
                        }}
                        spaceBetween={50}
                        slidesPerView={1}
                        loop={true}
                        autoplay={{
                            delay: 3000,
                            disableOnInteraction: false,
                        }}
                        onBeforeInit={(swiper) => {
                            swiper.params.navigation.prevEl = navigationPrevRef.current;
                            swiper.params.navigation.nextEl = navigationNextRef.current;
                        }}

                        speed={500}
                        modules={[Autoplay, Navigation]}
                    >
                        {BannerData.map((item, index) =>

                            <SwiperSlide>
                                <img key={index} className='w-full h-auto drop-shadow-lg' src={item.img} alt="" />
                            </SwiperSlide>
                        )}

                    </Swiper>

                    {/* <div className='mt-5 flex justify-center gap-3 items-center   md:hidden lg:hidden xl:hidden'>
                        <div ref={navigationPrevRef} className='w-8 h-8 select-none bg-white shadow-lg rounded-full flex justify-center items-center cursor-pointer active:opacity-50 border border-black/10'>
                            <img className='rotate-180' src="/assets/svg/arrow.svg" alt="" />
                        </div>
                        <div ref={navigationNextRef} className='w-8 h-8  select-none bg-white shadow-lg rounded-full flex justify-center items-center cursor-pointer active:opacity-50 border border-black/10'>
                            <img className='' src="/assets/svg/arrow.svg" alt="" />
                        </div>
                    </div> */}
                </div>

            </div>
        </>
    )
}

export default Banner