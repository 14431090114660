import React, { useState, createContext } from "react";
import { useEffect } from "react";

export const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const [PopupState, setPopupState] = useState(false);

    const value = {
        PopupState,
        setPopupState
    }
    useEffect(() => {

    }, []);

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );

};

export default AuthProvider