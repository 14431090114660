import React from 'react'

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper/modules';
import Button from '../../../components/Button';

export const Whatpeoplesays = ({modalHandler}) => {
    return (
        <>
            <div className='brand-container py-50 whatPeopleSays'>
                <p className='capitalize text-2xl md:text-3xl lg:text-3xl xl:text-4xl text-center font-semibold '>What People say!</p>
                
                <div className=' md:px-[80px] lg:px-[100px] xl:px-[100px] pt-10'>
                    <Swiper
                    className='px-3 py-3'
                        spaceBetween={30}
                        slidesPerView={1}
                        loop={true}
                        autoplay={{
                            delay: 30000,
                            disableOnInteraction: false,
                        }}
                        breakpoints={{
                            640: {
                              slidesPerView: 2,
                              spaceBetween: 20,
                            },
                            768: {
                              slidesPerView: 2,
                              spaceBetween: 30,
                            },
                            1200: {
                              slidesPerView: 3,
                              spaceBetween: 30,
                            },
                          }}

                        speed={500}
                        modules={[Autoplay, Navigation]}
                    >

                        <SwiperSlide>
                            <TestimonialCard />
                        </SwiperSlide>
                        <SwiperSlide>
                            <TestimonialCard />
                        </SwiperSlide>
                        <SwiperSlide>
                            <TestimonialCard />
                        </SwiperSlide>
                        <SwiperSlide>
                            <TestimonialCard />
                        </SwiperSlide>
                        <SwiperSlide>
                            <TestimonialCard />
                        </SwiperSlide>
                        <SwiperSlide>
                            <TestimonialCard />
                        </SwiperSlide>
                    </Swiper>

                    <div className='bg-orangrlight w-full rounded-lg flex flex-col md:flex-row lg:flex-row xl:flex-row py-10 justify-between items-center md:px-20 lg:px-20 xl:px-20 mt-50'>
                        <p className='text-black font-bold text-xl md:text-2xl lg:text-2xl xl:text-2xl pb-3'>Try our Easy Booking Service!</p>
                        <Button onClick={()=> modalHandler(true)} variant={'primary'} className={'font-bold'}>Easy Booking الحجز سهل</Button>
                    </div>
                </div>
            </div>
        </>
    )
}

const TestimonialCard = () => {
    return (
        <>
            <div className='w-[100%] flex flex-col gap-1 '>
            
                <div className='flex flex-col items-center'>
                    <img src="assets/images/PeopleSays/people.png" alt="" className='z-10'/>
                    <div className='text-center shadow-lg pt-10 pb-3 px-3 carosuel'>
                        <p className='text-bold text-md pb-3'>Nithin Sharma</p>
                        <p className='text-xs pLineh'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                    </div>
                </div>
            </div>
        </>
    )
}